import React from "react"
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

function Header({center, showText}){
    return (
        <header style={{backgroundColor: "inherit"}}>
            <Link to="/">
                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div style={{display: "block"}}>
                        <StaticImage
                            layout="fixed"
                            src="../images/LegalSoftware-Logo-transparent.png"
                            width={56}
                            height={56}
                            placeholder="blurred"
                            alt="Legal Software Logo"
                        />
                    </div>
                    {
                        showText ? (
                            <div style={{display: "block", verticalAlign: "top", marginLeft: "1rem"}}>
                                <h5 className="bold" style={{lineHeight: "56px", color: "rgb(140,140,140)"}}>Legal Software</h5>
                            </div>
                        ) : null
                    }

                </div>
            </Link>
        </header>
    )
}

export default Header