import * as React from "react"
import {Link, graphql, useStaticQuery} from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/Layout";
import {getImage, StaticImage} from "gatsby-plugin-image";
import {BgImage} from "gbimage-bridge";
import Header from "../components/Header";
import Icon from "../components/presentation/Icon";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

const About = ({ data, location }) => {

    const { patternImage } = useStaticQuery(
        graphql`
          query {
            patternImage: file(relativePath: { eq: "Pattern1.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `
    );

    const pattern = getImage(patternImage)

    return (
        <Layout>
            <Seo title="Über uns" />
            <div style={{
                width: "100vw",
                minHeight: "100vh",
                position: "relative",
                backgroundColor: "#f0f0f0"
            }}>
                <BgImage
                    image={pattern}
                    Tag="div"
                    preserveStackingContext={true}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 0,
                        backgroundRepeat: "repeat",
                        backgroundPosition: "left top",
                        backgroundSize: "80px 80px",
                        opacity: 0.4
                    }}
                    alt="Legal Software Effect"
                />
                <div style={{position: "relative", zIndex: 10, padding: "1rem 0"}}>
                    <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: "2rem"}}
                        className="b2"
                    >
                        <Link to="/">
                            <StaticImage
                                layout="fixed"
                                src="../images/LegalSoftware-Logo-transparent.png"
                                width={128}
                                height={128}
                                placeholder="blurred"
                                alt="Legal Software Logo"
                            />
                        </Link>
                    </div>
                    <div className="container">
                        <div className="b2">
                            <h1 style={{ fontSize: "4.0rem"}}>Legal Software</h1>
                            <h2 style={{ fontSize: "2.0rem", color: "#E91E63"}}>Wir entwickeln digitale Plattformen und
                                SaaS-Lösungen, um die Rechtsbranche grundlegend zu transformieren.</h2>
                        </div>
                        <div className="row b1">
                            <div className="col-md-8 col-md-offset-2 col-12">
                                <div className="card">
                                    <h3 style={{display: "inline-block", borderBottom: "solid 5px #039BE5"}}>Über uns</h3>
                                    <p className="light" style={{fontSize: "1.5rem"}}>
                                        Wir transformieren Arbeitsprozesse und Gedankengänge von Juristen und
                                        reformieren die Interaktion zwischen Rechtsberater und Mandant.
                                    </p>
                                    <p>
                                        Digitalisierung ist nicht mit einer Dokumentenablage und der Benutzung der
                                        gängigen Office-Programme abgeschlossen. Neueste Technologien sind der Schlüssel,
                                        um die Kanzleiorganisation, Mandatsbearbeitung und Mitarbeiterintegration
                                        nachhaltig effektiver und effizienter zu gestalten. Unsere Lösungen werden dazu
                                        beitragen, die Branche in eine agilere, innovativere und erfolgreichere Zukunft zu führen.

                                    </p>
                                    <p>
                                        Wir haben uns zum Ziel gesetzt, die Legal-Tech-Branche wiederzubeleben und
                                        fühlen uns mit unserer rechtlichen wie technischen Expertise für dieses Projekt
                                        bestens gerüstet.
                                    </p>
                                    <p>
                                        Wir haben den Anspruch an uns selbst, von uns sagen zu können:
                                        Wir bieten die beste Legal Software.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <h2 style={{
                            fontSize: "3.0rem",
                            display: "inline-block",
                            borderBottom: "solid 7px #E91E63"
                        }} className="b2">Unser Team</h2>


                        <div className="row b2"
                             id="max"
                             style={{display: "flex", flexDirection: "row-reverse", flexWrap: "wrap"}}>
                            <div className="col-md-6" style={{flexGrow: "1"}}>
                                <div className="b2" style={{display: "flex", justifyContent: "center"}}>
                                    <div style={{borderBottom: "solid 7px #039BE5", overflow: "hidden"}}>
                                        <StaticImage
                                            layout="constrained"
                                            width={347}
                                            height={650}
                                            src="../images/max_standing.png"
                                            placeholder="blurred"
                                            style={{backgroundSize: "contain"}}
                                            alt="Legal Software Logo"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card b2">
                                    <h3 style={{margin: 0}}>Max Pschiebel</h3>
                                    <span className="light" style={{fontSize: "1.5rem"}}>CEO</span>
                                    <p>
                                        Bereits vor Abschluss des ersten Staatsexamens habe ich an der Digitalisierung
                                        des Workflows in mittelständischen Kanzleien gearbeitet. Dabei habe ich einige
                                        Erfahrungen zu den Arbeitsabläufen und Bedürfnissen der Branche gesammelt.
                                        Nun möchte ich erreichen, dass Anwälte ihre Arbeitszeit nicht als lineare,
                                        sondern als skalierbare Einnahmequelle betrachten.
                                    </p>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "2rem"
                                    }}>
                                        <div>
                                            <a href="https://www.linkedin.com/in/maximilian-pschiebel-38a654143/" target="_blank" className="button social"
                                               style={{margin: "0 10px", backgroundColor: "#1e88e5"}}>
                                                <Icon icon={faLinkedinIn}/>
                                            </a>
                                            <a href="mailto:max@legalsoftware.io" target="_blank" className="button social"
                                               style={{margin: "0 10px", backgroundColor: "#000"}}>
                                                <Icon icon={faEnvelope}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row b2" id="tom">
                            <div className="col-md-6">
                                <div className="b2" style={{display: "flex", justifyContent: "center"}}>
                                    <div style={{borderBottom: "solid 7px #039BE5", overflow: "hidden"}}>
                                        <StaticImage
                                            layout="constrained"
                                            height={650}
                                            src="../images/tom_standing.png"
                                            placeholder="blurred"
                                            style={{backgroundSize: "contain"}}
                                            alt="Legal Software Logo"
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="card b2">
                                    <h3 style={{margin: 0}}>Tom Schamberger</h3>
                                    <span className="light" style={{fontSize: "1.5rem"}}>CTO</span>
                                    <p>
                                        Ich studiere Data Science im Master an der TU München und forsche zur
                                        Anonymisierung von Rechtstexten mithilfe von AI (NLP). Vor der Selbstständigkeit
                                        habe ich an Projekten in den Bereichen Big Data Analysis, Machine Learning und
                                        Web App Design gearbeitet. Nun arbeite ich daran, den Rechtsbereich innovativer
                                        und zukunftsfähiger zu gestalten.
                                    </p>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "2rem"
                                    }}>
                                        <div>
                                            <a href="https://www.linkedin.com/in/tom-schamberger-3ab91417b/" target="_blank" className="button social"
                                               style={{margin: "0 10px", backgroundColor: "#1e88e5"}}>
                                                <Icon icon={faLinkedinIn}/>
                                            </a>
                                            <a href="mailto:tom@legalsoftware.io" target="_blank" className="button social"
                                               style={{margin: "0 10px", backgroundColor: "#000"}}>
                                                <Icon icon={faEnvelope}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default About